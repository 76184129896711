<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="content">
        <a-card title="积分规则">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="等级名称" prop="levelName">
                  <a-input v-model="form.levelName" :maxLength="50" :disabled="isDetail" allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="达标金额" prop="amount">
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <a-input-number class="x-inner" size="large" v-model="form.amount" allowClear :disabled="isDetail" :min="1" :max="9999999999"></a-input-number>
                    </a-col>
                    <a-col :span="4">元 </a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="积分（消费1元积分）" prop="integral">
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <a-input-number class="x-inner" size="large" v-model="form.integral" allowClear :disabled="isDetail" :min="0" :max="9999999999"></a-input-number>
                    </a-col>
                    <a-col :span="4">积分 </a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="折上折" prop="discount">
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <a-input-number class="x-inner" size="large" v-model="tempDiscount" allowClear :disabled="isDetail" :min="1"  :step="0.1" @change="discountChange"></a-input-number>
                    </a-col>
                    <a-col :span="4">折 </a-col>
                  </a-row>
<!--                  <a-select v-model="form.discount" allowClear :disabled="isDetail">-->
<!--                    <a-select-option :value="1" >-->
<!--                      10折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.9">-->
<!--                      9折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.8">-->
<!--                      8折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.7">-->
<!--                      7折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.6">-->
<!--                      6折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.5">-->
<!--                      5折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.4">-->
<!--                      4折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.3">-->
<!--                      3折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.2">-->
<!--                      2折-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="0.1">-->
<!--                      1折-->
<!--                    </a-select-option>-->
<!--                  </a-select>-->
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="备注" prop="remarks">
                  <a-input v-model="form.remarks" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="规则状态" prop="status">
                  <a-switch checked-children="启用" un-checked-children="禁用" :checked="form.status === 0" @change="handleChangeStatus" :disabled="isDetail" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { pointsAddOrEdit,pointsInfo } from '@/api/points';
import formMixin from '@/mixin/formMixin';
const Big = require('big.js')
export default {
  name: 'PointsForm',
  mixins: [formMixin],
  data() {
    return {
      tempDiscount:10,
      form: {
        levelName: '',
        amount: '',
        integral: '1',
        discount: 10,
        status: 0,
        remarks:''
      },
      rules: {
        levelName: [
          {
            required: true,
            message: '请输入等级名称',
            trigger: 'blur',
          },
        ],
        amount:[
          {
            required: true,
            message: '请输入达标金额'
          }
        ],
        integral: [
          {
            required: true,
            message: '请输入积分'
          }
        ],
        discount: [
          {
            required: true,
            message: '请选择折扣'
          }
        ],
        status: [
          {
            required: true,
            message: '请选择规则状态'
          }
        ],
      },
    };
  },
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  methods: {
    discountChange(value) {
      if(value > 10){
        this.form.discount = 11
        this.$message.error('请检查折扣');
      }else{
        this.form.discount = 10
      }
      console.log('changed', value);
    },
    // 获取信息
    getData(pointsId) {
      pointsInfo(pointsId).then((data) => {
        this.form = data;
        this.tempDiscount = parseFloat(Big(data.discount).times(10).toString())
        console.log(this.tempDiscount.toString())
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if(this.form.discount > 10){
            this.$message.error('请检查折扣');
            return false;
          }
          let x = Big(this.tempDiscount).div(10)
          this.form.discount = Math.floor(parseFloat(x) * 100) / 100   // 输出结果为 15.77 x.toFixed(2)
          const params = {
            ...this.form,
          };
          params.status === params.status ? 1 : 0
          pointsAddOrEdit(params).then((res) => {
            if(res){
              this.$message.success(res.message || '操作成功');
              this.handleBack();
            }else{
              this.$message.error('等级名称请勿重复');
              return false;
            }

          })
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
    handleChangeStatus(status) {
      this.form.status = status ? 0 : 1;
    }
  }
};
</script>

<style lang="less" scoped>

</style>
